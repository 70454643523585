<template>
    <div>
        <div class="position-relative">
            <img src="@/assets/share_banner.jpg" class="w-100 img-fluid" alt="">
            <div class="position-absolute ps-3 pt-1 text-dark d-flex flex-column justify-content-center text-start"
                style="left: 0;top:0;bottom:0">
                <div class="fw-bolder mb-0" style="font-weight: bolder;">
                    Refer a friend
                </div>
                <div class="fw-bold mb-0" style="line-height: 1;">
                    Earn cryptocurrency together
                </div>
                <div class="fw-bold mb-0">
                    Earn up to 10% commission in DF-ETH
                </div>
                <button style="font-size: small;width: 100px;" class="btn py-1 mt-2 text-dark rounded-pill btn-sm">
                    View details</button>
            </div>
        </div>
    </div>
</template>
<style scoped>
@keyframes textColor {
    0% {
        color: #8e664b;
    }

    50% {
        color: #000;
    }

    100% {
        color: #8e664b;
    }
}

.btn {
    border: 1px solid rgba(0, 0, 0, .2) !important;
    font-weight: bold;
    font-size: .8rem;
}
</style>