<template>
    <div>
        <BannerComponent></BannerComponent>

        <div class="p-3">
            <PoolDataComponent class="mt-3 mb-2"></PoolDataComponent>
            <iframe width="100%" allowfullscreen frameborder="0" height="239.375"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                title src="https://www.youtube.com/embed/IP3jF_LRouQ"></iframe>
            <MinerIncomeComponent class="mt-4"></MinerIncomeComponent>
            <HelpCenterComponent class="mt-4"></HelpCenterComponent>
        </div>
        <FooterComponent class="mt-4"></FooterComponent>
    </div>
</template>
<script>
import BannerComponent from '@/components/home/BannerComponent.vue';
import MinerIncomeComponent from '@/components/home/MinerIncomeComponent.vue';
import PoolDataComponent from '@/components/home/PoolDataComponent.vue';
import HelpCenterComponent from '@/components/home/HelpCenterComponent.vue';
import FooterComponent from '@/components/FooterComponent.vue';

export default {
    components: { BannerComponent, PoolDataComponent, MinerIncomeComponent, HelpCenterComponent, FooterComponent }
}
</script>
<style scoped>
.text-pri {
    color: #5e72a6 !important;
}
</style>