import { createApp } from 'vue'
import router from './router'
import App from './App.vue'


import { createI18n } from 'vue-i18n'



import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import store from './store'

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

import en from '@/assets/lang/en.json';
import cn from '@/assets/lang/cn.json';

import { createHead } from '@vueuse/head'

const head = createHead()

library.add(fas)
library.add(far)
library.add(fab)

const i18n = createI18n({
  locale: 'en',
  allowComposition: true, // you need to specify that!
  messages: {
    en,cn
  }
})




const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router)
app.use(i18n)
app.use(head)
app.use(store)



app.mount('#app')