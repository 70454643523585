<template>
    <div class="px-3 pb-3 d-flex justify-content-center flex-column align-items-center">
        <h4 class="text-dark fw-bolder text-center"><b>Regulatory Authority</b></h4>
        <div class="line" style="width: 30%;"></div>
        <p class="text-pri">We have a secure audit report</p>
        <div class="row">
            <div class="col-4">
                <img class="w-100 img-fluid" src="@/assets/audit1.png">
            </div>
            <div class="col-4">
                <img class="w-100 img-fluid" src="@/assets/audit2.png">

            </div>
            <div class="col-4">
                <img class="w-100 img-fluid" src="@/assets/audit3.png">

            </div>
        </div>

        <div class="mt-4 d-flex flex-column align-items-center">
            <h4 class="text-dark fw-bolder text-center"><b>Partner</b></h4>
            <div class="line" style="width: 30%;"></div>
            <p class="text-pri">Our business partners</p>
            <div class="row">
                <div class="col-4 mb-2">
                    <img class="w-100 img-fluid" src="@/assets/coinbase.png">
                </div>
                <div class="col-4 mb-2">
                    <img class="w-100 img-fluid" src="@/assets/metamask.png">
                </div>
                <div class="col-4 mb-2">
                    <img class="w-100 img-fluid" src="@/assets/okx.png">
                </div>

                <div class="col-4 mb-2">
                    <img class="w-100 img-fluid" src="@/assets/huobi.png">
                </div>
                <div class="col-4 mb-2">
                    <img class="w-100 img-fluid" src="@/assets/token_pock.png">
                </div>
                <div class="col-4 mb-2">
                    <img class="w-100 img-fluid" src="@/assets/defix_box.png">
                </div>
            </div>
        </div>
    </div>
</template>